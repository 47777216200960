<script lang="ts" setup>
// Properties
import InputTag from "~/components/form/InputTag.vue";
import H1Heading from "~/components/H1Heading.vue";
import FeatureInfo from "~/components/product/FeatureInfo.vue";
import PriceInfo from "~/components/product/PriceInfo.vue";
import WishList from "~/components/product/WishList.vue";
import axios from "axios";
import type RecentlyViewedResource from "~/types/RecentlyViewedResource";
import ModalBox from "~/components/ModalBox.vue";
import { useCartStore } from "~/store/cart";
import LookInside from "~/components/product/LookInside.vue";
import type Product from "~/types/Product";
import { useCartPrices } from "~/composables/cart";
import type StatamicCollectionRequest from "~/types/requests/StatamicCollectionRequest";
import type { PropType } from "vue";

const props = defineProps({
    product: {
        type: Object as PropType<Product>,
        required: true,
    },
    showAddToWishlist: {
        type: Boolean,
        default: true,
    },
    removeFromWishlistAfterAdd: {
        type: Boolean,
        default: false,
    },
});

// Data
const router = useRouter();
const route = useRoute();
const config = useRuntimeConfig();
const showLookInside = ref(false);
const pid = props.product.c_id ?? props.product.id;

const COLOR_PRINT = useCartPrices().COLOR_PRINT;
const B_W_PRINT = useCartPrices().B_W_PRINT;
const DIGITAL_ONLY = useCartPrices().DIGITAL_ONLY;

const featureOptions = ref([]);

// If features are not passed in, fetch them from the API
if (props.product.features && props.product.features?.[0]?.title) {
    featureOptions.value = JSON.parse(JSON.stringify(props.product.features));
} else {
    const { data: FeatureData } = await useFetch<StatamicCollectionRequest>(
        config.public.STATAMIC_API + `/collections/products/entries/${props.product.id ?? props.product.c_id}`,
    );
    featureOptions.value = FeatureData.value?.data?.features ?? [];
}

const features = computed(() => {
    const ary = [] as string[];
    // Filter out "Printed Workbook" base on user selection
    for (const feature of featureOptions.value) {
        if (orderType.value === "Digital Only" && feature["title"] !== "Printed Workbook") {
            ary.push(feature["title"]);
            continue;
        }
        if (orderType.value.includes("Print")) {
            ary.push(feature["title"]);
        }
    }
    return ary;
});

const cartItem = await useCartStore().fetchCartItem(pid, (route.query?.orderType ?? "") as string);
const orderType = ref(cartItem ? cartItem.order_type : props.product.color_option ? COLOR_PRINT : B_W_PRINT);
const minQty = computed(() => {
    if (orderType.value === COLOR_PRINT && props.product.color_option) {
        if (!props.product.qty_25_minimum && props.product?.price_color_base) {
            return 10;
        }
        return 25;
    } else if (orderType.value === DIGITAL_ONLY && props.product.digital_option) {
        return 50;
    } else if (props.product.qty_25_minimum) {
        return 25;
    }

    return 10;
});
const maxQty = ref(useCartStore().maxQty);
const qty = ref(cartItem ? cartItem.qty : minQty.value);

const priceOptions = computed(() => {
    // Color + digital
    if (props.product.color_option && orderType.value == COLOR_PRINT) {
        const options = [];
        if (!props.product.qty_25_minimum && props.product.price_color_base) {
            options.push({
                qty: "10-24",
                price: props.product.price_color_base.toFixed(2),
                highlightPrice: true,
            });
        }
        if (props.product.price_color_bulk) {
            options.push({
                qty: "25+",
                price: props.product.price_color_bulk.toFixed(2),
                highlightPrice: false,
            });
        }
        if (options.length > 0) return options;
    }
    // Digital only
    if (props.product.digital_option && orderType.value == DIGITAL_ONLY && props.product.price_digital) {
        return [{ qty: "50+", price: props.product?.price_digital.toFixed(2), highlightPrice: false }];
    }
    // B&W + digital
    if (props.product.qty_25_minimum) {
        return [{ qty: "25+", price: props.product?.price_bulk.toFixed(2), highlightPrice: false }];
    }

    return [
        { qty: "10-24", price: props.product?.price_base.toFixed(2), highlightPrice: true },
        { qty: "25+", price: props.product?.price_bulk.toFixed(2), highlightPrice: false },
    ];
});
const showModalBox = ref(false);

const recordView = async () => {
    const viewedResource = {
        id: pid,
        slug: props.product.c_slug ?? props.product.slug,
        title: props.product.title,
        photo: props.product.photo,
        photo_2x: props.product.photo_2x,
        photo_lg: props.product.photo_lg,
        photo_lg_2x: props.product.photo_lg_2x,
    };

    const endpoint = config.public.STATAMIC_API + `/store/recently-viewed`;

    const recentlyViewedResources: Array<RecentlyViewedResource> = JSON.parse(
        localStorage.getItem("recentlyViewedResources") || "[]",
    );

    // append the current item to the front of the list so that we see the newest item first
    recentlyViewedResources.unshift(viewedResource);

    // get unique values only
    let unique = [...new Map(recentlyViewedResources.map((item) => [item["id"], item])).values()];
    // keep only the first 10 items
    unique = unique.slice(0, 10);

    // update the localstorage with the new list
    localStorage.setItem("recentlyViewedResources", JSON.stringify(unique));

    // send the view to the server
    await axios.post(endpoint, viewedResource);
};

if (import.meta.client) {
    recordView();
}

const addToCartSuccessfull = ref(false);
const addToCart = async () => {
    try {
        if (qty.value < minQty.value) {
            return;
        }

        addToCartSuccessfull.value = false;
        const items = await useCartStore().upsertItem(pid, qty.value, orderType.value);
        if (items && items.length > 0) {
            addToCartSuccessfull.value = true;
            useTrackEvent("add_to_cart", {
                product_id: props.product.id,
                product_name: props.product.title,
                product_isbn: props.product.isbn,
            });
        }
        showModalBox.value = true;
    } catch (error) {
        console.log(error);
    }
};

watch(showModalBox, async (newVal, oldVal) => {
    if (newVal === false && props.removeFromWishlistAfterAdd) {
        await useWishlistStore().deleteItem(pid);
    }
});
watch(orderType, () => {
    qty.value = minQty.value;
});

const validateQty = () => {
    if (qty.value < minQty.value) {
        qty.value = minQty.value;
    }
    if (qty.value > maxQty.value) {
        qty.value = maxQty.value;
    }
};
</script>

<template>
    <div class="p-4 md:flex">
        <div
            class="flex-none md:w-1/3 md:pr-12"
            @mouseover="showLookInside = true"
            @mouseleave="showLookInside = false"
        >
            <H1Heading class="mb-4 md:hidden" size="text-xl" line-height="leading-2">{{ product.title }}</H1Heading>
            <div class="relative">
                <img
                    :src="product.photo_xl"
                    :srcset="`${product.photo_lg} 1x, ${product.photo_lg_2x} 2x`"
                    width="282"
                    height="365"
                    :alt="'Cover Image ' + product.title"
                    class="mx-auto mb-3 border border-gray-300 shadow-md shadow-gray-400"
                />
                <div class="absolute bottom-2 w-full text-center md:bottom-4">
                    <LookInside v-model:show-button="showLookInside" :product="product" />
                </div>
            </div>
        </div>

        <div class="flex-none pt-6 md:w-2/3 md:pt-0">
            <H1Heading class="hidden md:block" size="text-2xl md:text-3xl" line-height=""
                >{{ product.title }}
            </H1Heading>
            <div class="text-sm text-gray-500">ISBN: {{ product.isbn }}</div>
            <hr class="my-4 hidden border-gray-300 md:block" />

            <!-- Product types -->
            <div class="text-center md:text-left">
                <button
                    v-if="product.color_option"
                    class="mr-4 mb-4 rounded-sm px-5 py-2 hover:border-2 hover:border-gray-700"
                    :class="
                        orderType === COLOR_PRINT ? 'bg-abc-gray border-2 border-gray-700' : 'border border-gray-300'
                    "
                    @click="orderType = COLOR_PRINT"
                >
                    Color Print + Digital
                </button>
                <button
                    class="mr-4 mb-4 rounded-sm px-5 py-2 hover:border-2 hover:border-gray-700"
                    :class="orderType === B_W_PRINT ? 'bg-abc-gray border-2 border-gray-700' : 'border border-gray-300'"
                    @click="orderType = B_W_PRINT"
                >
                    B&W Print + Digital
                </button>
                <button
                    v-if="product.digital_option"
                    class="mr-4 mb-4 rounded-sm px-5 py-2 hover:border-2 hover:border-gray-700"
                    :class="
                        orderType === DIGITAL_ONLY ? 'bg-abc-gray border-2 border-gray-700' : 'border border-gray-300'
                    "
                    @click="orderType = DIGITAL_ONLY"
                >
                    Digital Only
                </button>
            </div>

            <!-- Prices & features -->
            <div class="md:flex">
                <PriceInfo :prices="priceOptions" class="flex-none md:w-2/5 md:pr-7" />
                <FeatureInfo :features="features" class="hidden w-3/5 grid-flow-col grid-rows-3 md:grid" />
            </div>

            <!-- Add to cart -->
            <form @submit.prevent="addToCart">
                <div class="md:bg-abc-gray relative mt-8 md:mt-4 md:p-6 lg:flex xl:max-w-[800px]">
                    <div class="flex-none text-center">
                        <div class="mr-2 mb-1 inline text-sm md:text-center lg:mr-0 lg:block">
                            Quantity (min {{ minQty }})
                        </div>
                        <InputTag
                            v-model="qty"
                            type="number"
                            class="w-full rounded-full text-center md:w-32"
                            :min="minQty"
                            :max="maxQty"
                            @change="validateQty"
                        />
                    </div>
                    <div class="relative flex-auto pt-4 text-sm md:px-6 xl:mt-4 xl:flex xl:pt-0">
                        <ButtonPrimary type="submit" class="w-full xl:w-1/2"> Add to Cart</ButtonPrimary>
                        <ButtonSecondary
                            v-if="product.coursewave_product_id"
                            type="link"
                            class="mt-2 w-full overflow-hidden pt-3 whitespace-nowrap xl:mt-0 xl:ml-2 xl:w-1/2"
                            :href="`/free-trial/${product.c_slug}`"
                        >
                            FREE Two-Week Trial!
                        </ButtonSecondary>
                    </div>

                    <div class="absolute -top-2 right-2 md:top-2">
                        <WishList :product="product" />
                    </div>
                </div>
            </form>

            <hr class="mt-8 mb-4 md:hidden" />
            <FeatureInfo :features="features" class="grid grid-flow-col grid-rows-3 md:hidden" />
        </div>

        <ModalBox v-model:show-modal-box="showModalBox" width="w-11/12 sm:w-3/4 lg:w-1/2 xl:w-2/5">
            <div class="flex text-center md:text-left">
                <div class="hidden flex-none bg-red-50 md:block">
                    <NuxtLink :to="`/products/${product.c_slug}`">
                        <img
                            :src="product.photo_xl"
                            :srcset="`${product.photo_xl} 1x, ${product.photo_xl_2x} 2x`"
                            width="282"
                            height="365"
                            :alt="'Cover Image ' + product.title"
                            class="mx-auto mb-3 w-32 border border-gray-300 shadow-md shadow-gray-400 lg:w-40"
                        />
                    </NuxtLink>
                </div>
                <div class="flex-1 md:pl-6">
                    <H2Heading class="text-abc-blue mb-4" size="text-base md:text-lg" line-height="leading-tight">
                        {{ product.title }}
                    </H2Heading>
                    <div v-if="addToCartSuccessfull">
                        <div class="my-6">Item has been added to your cart.</div>
                        <NuxtLink to="/cart" class="text-abc-blue hidden md:inline-block">View my cart</NuxtLink>
                    </div>
                    <div v-else class="text-abc-red my-6">Failed to add item to cart. Please try again later.</div>
                </div>
            </div>

            <div class="mt-6 text-center">
                <ButtonPrimary class="w-32 md:w-2/3" @click="showModalBox = false">OK</ButtonPrimary>
                <ButtonSecondary class="ml-4 md:hidden" @click="router.push('/cart')">View Cart</ButtonSecondary>
            </div>
        </ModalBox>
    </div>
</template>
