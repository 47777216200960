<script setup lang="ts">
import { onMounted, ref } from "vue";
import type Product from "~/types/Product";
import { useWishlistStore } from "~/store/wishlist";
import { useCartStore } from "~/store/cart";
import { useUserStore } from "~/store/user";
import { navigateTo } from "#app";

const props = defineProps({
    product: {
        type: Object as () => Product,
        required: true,
    },
});

const pid = ref(props.product.c_id ?? props.product.id);

const inWishlist = computed(() => {
    const wishlist = useWishlistStore().items.map((product: Product) => product.id);
    return wishlist.includes(pid.value);
});
const feedbackMessage = ref("");

const toggleWishlist = async () => {
    if (!useUserStore().isLoggedIn) {
        navigateTo("/login");
        return;
    }

    console.log("PID:", pid.value);
    if (useWishlistStore().itemExist(pid.value)) {
        await useWishlistStore().deleteItem(pid.value);
        return;
    }
    await useWishlistStore().insertItem(pid.value);
    await useCartStore().deleteItem(pid.value); // Remove it from cart, if apply
};

onMounted(async () => {
    await useWishlistStore().fetchItems();
});
</script>

<template>
    <div class="relative inline-block">
        <button type="button" class="ml-4" title="Wishlist" @click="toggleWishlist">
            <img
                v-if="useWishlistStore().itemExist(pid as number)"
                src="/images/fa/heart-solid.svg"
                class="h-6 w-6"
                alt="Heart filled"
            />
            <img v-else src="/images/fa/heart-regular.svg" class="h-6 w-6" alt="Heart outline" />
        </button>
        <p
            v-if="feedbackMessage"
            class="absolute top-0 left-0 -translate-y-full transform rounded-2xl bg-blue-400 p-2 text-white"
        >
            {{ feedbackMessage }}
        </p>
    </div>
</template>
