<script lang="ts" setup>
// Properties
const props = defineProps({
    features: {
        type: Object,
    },
});

// Data
</script>

<template>
    <div>
        <div v-for="feature in props.features" :key="feature" class="">
            <NuxtImg src="/images/fa/check-regular-green.svg" class="mr-2 inline-block w-4"></NuxtImg>
            {{ feature }}
        </div>
    </div>
</template>

<style scoped></style>
