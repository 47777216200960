<script lang="ts" setup>
// Properties
const props = defineProps({
    prices: {
        type: Object,
    },
});

// Data
</script>

<template>
    <div>
        <div class="flex rounded-t-md border border-gray-300 text-center font-bold">
            <div class="w-2/5 flex-none py-0.5">
                <span class="md:hidden lg:block">Quantity</span>
                <span class="hidden md:block lg:hidden">Qty</span>
            </div>
            <div class="w-3/5 flex-none border-l border-gray-300 py-0.5">Price</div>
        </div>
        <ul>
            <li
                v-for="row in props.prices"
                :key="`price-${row.qty}`"
                class="flex border border-t-0 border-gray-300 text-center text-sm last:rounded-b-lg"
            >
                <div class="w-2/5 flex-none py-1.5">{{ row.qty }}</div>
                <div
                    class="w-3/5 flex-none border-l border-gray-300 py-1.5 ease-in"
                    :class="row.highlightPrice ? 'bg-abc-gray text-red-600' : ''"
                >
                    ${{ row.price }}
                </div>
            </li>
        </ul>
    </div>
</template>

<style scoped></style>
